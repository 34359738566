import initialState from '../reducers/initialState';
import { STORE_CARD_INFO } from '../actions/actionTypes';

export default function cardInfoReducer(
  state = initialState.cardInfo,
  action: {
    type: any;
    payload: { lastFourDigit: string; encryptedPID: string };
  }
) {
  switch (action.type) {
    case STORE_CARD_INFO:
      return {
        ...state,
        lastFourDigit: action.payload.lastFourDigit,
        encryptedPID: action.payload.encryptedPID,
      };
    default:
      return state;
  }
}
