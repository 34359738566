import React from 'react';
import img from '../assets/images/Chase_Logo_Octagon_White.svg';

const Header: React.FC = (props) => {
  return (
    <header>
      <div className={'app-bar mds-d-flex mds-jc-start mds-ai-center mds-px-5'}>
        <img src={img} alt="Chase Logo" />
      </div>
    </header>
  );
};
export default Header;
