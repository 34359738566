import React from 'react';
import { PageStatus } from '../../constants/PageStatus';
import ReEnableProfile from './ReEnableProfile';
import ErrorPage from '../Error/ErrorPage';
import { RootStateOrAny, useSelector } from 'react-redux';
import ReEnableProfileConfirmation from './ReEnableProfileConfirmation';

function ReEnableProfilePage() {
  const pageStatus = useSelector(
    (state: RootStateOrAny) => state.pageInfo.pageStatus
  ) as PageStatus;

  if (pageStatus == PageStatus.INITIAL) return <ReEnableProfile />;
  else if (pageStatus == PageStatus.SUCCESS)
    return <ReEnableProfileConfirmation />;
  else return <ErrorPage />;
}

export default ReEnableProfilePage;
