import { SHOW_PROGRESS_SPINNER, HIDE_PROGRESS_SPINNER } from './actionTypes';

const showSpinner = () => {
  return {
    type: SHOW_PROGRESS_SPINNER,
  };
};
const hideSpinner = () => {
  return {
    type: HIDE_PROGRESS_SPINNER,
  };
};

export { showSpinner, hideSpinner };
