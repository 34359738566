export const DISCLAIMER = {
  headerText:
    'Your contact details were stored during a recent digital receipt request at a Chase for Business customer. Your privacy is very important to us, and you can see how we protect your information by visiting our ',
  subText:
    'For more details about how your contact information is stored, see our ',
  fdicText: 'JPMorgan Chase Bank, N.A. Member FDIC',
  copyrightText: 'JPMorgan Chase & Co.',
};

export const privacyPolicyUrl = 'https://www.chase.com/digital/resources/privacy-security/privacy/online-privacy-policy';
export const supportPageUrl = 'https://chase.com/consumerprofile';
