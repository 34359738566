import React from 'react';

/**
 * Loading MDS stylesheets here
 * Please exclude the styles which is not relevant for your project
 *
 * If you are using this application as single SPA micro app, please move the imports to container app
 * For standalone testing of SPA micro app, please keep these imports inside index.tsx
 *
 * */
import '@mds/resources-web/dist/tokens/components/MdsComponents-cpo.css';
import '@mds/resources-web/dist/tokens/foundation/MdsFoundation-cpo.css';
import '@mds/resources-web/dist/grid-system/mds-grid-system.css';
import '@mds/resources-web/dist/typography/mds-typography.css';
import '@mds/resources-web/dist/utils/mds-utilities.css';
import '@mds/chase-web-icons/dist/iconfont/mds-chase-icons.css';
import { Route, Router, Switch } from 'react-router-dom';
import browserHistory from './utils/history';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import './styles/App.css';
import Spinner from './component/Progress/Spinner';
import ReEnableProfilePage from './component/ReEnableProfile/ReEnableProfilePage';
import {
  MANAGE_PROFILE_CONTEXT,
  NOT_MY_RECEIPT_CONTEXT,
  RE_ENABLE_PROFILE_CONTEXT,
} from './constants/app-constants';
import RemoveMyEmailPage from './component/RemoveMyEmail/RemoveMyEmailPage';
import RemoveProfilePage from './component/RemoveProfile/RemoveProfilePage';
import {useDispatch} from "react-redux";
import {getLastFourCardNumber} from "./actions/pageActions";
import NotFoundErrorPage from "./component/NotFoundError/NotFoundErrorPage";

MDS.brand = 'cpo';

const mdsAt576 = 'mds-col-12';
const mdsAt768 = 'mds-col-at-768-10 mds-offset-at-768-1';
const mdsAt992 = 'mds-col-at-992-6 mds-offset-at-992-3';
const mdsAt1200 = 'mds-col-at-1200-6 mds-offset-at-1200-3';

function App({ history = browserHistory }) {
    const dispatch = useDispatch();
  return (
    <div onLoad={()=> dispatch(getLastFourCardNumber())}>
      <Spinner />
      <nav className="topnav">
        <Header />
      </nav>
      <main className="mds-container-fluid">
        <div className={'mds-row'}>
          <div
            className={`${mdsAt576} ${mdsAt768} ${mdsAt992} ${mdsAt1200} mds-mb-7 mds-px-5`}
          >
            <Router history={history}>
              <Switch>
                <Route
                  path={NOT_MY_RECEIPT_CONTEXT + ':paymentInstrumentId'}
                  exact
                  render={() => <RemoveMyEmailPage />}
                />
                <Route
                  path={MANAGE_PROFILE_CONTEXT + ':paymentInstrumentId'}
                  exact
                  render={() => <RemoveProfilePage />}
                />
                <Route
                  path={RE_ENABLE_PROFILE_CONTEXT + ':paymentInstrumentId'}
                  exact
                  render={() => <ReEnableProfilePage />}
                />
                  <Route component={NotFoundErrorPage} />
              </Switch>
            </Router>
          </div>
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
