import * as actionTypes from './actionTypes';
import httpService from '../services/httpService';
import logger from '../services/logService';
import CONFIG from '../config/env';
import { hideSpinner, showSpinner } from './spinnerActions';
import { PageStatus } from '../constants/PageStatus';
import { LinkType } from '../constants/LinkType';

function storePageInfo(pageStatus: PageStatus) {
  return {
    type: actionTypes.STORE_PAGE_INFO,
    payload: { pageStatus },
  };
}

function storeCardInfo(lastFourDigit: string, encryptedPID: string) {
  return {
    type: actionTypes.STORE_CARD_INFO,
    payload: { lastFourDigit, encryptedPID },
  };
}

export function getLastFourCardNumber() {
  return function (dispatch: any) {
    dispatch(showSpinner());
    const pathName = window.location.pathname;
    const pathParams_arr = pathName.split('/');
    const paymentInstrumentId = pathParams_arr[3];
    const url = `${CONFIG.CRS_CONSUMER_CONSENT_SERVICE}/crs/public/api/v1/consumer-consents/${paymentInstrumentId}`;
    return httpService
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          dispatch(hideSpinner());
          dispatch(
            storeCardInfo(response.data.lastFourDigit, paymentInstrumentId)
          );
          dispatch(storePageInfo(PageStatus.INITIAL));
        } else {
          dispatch(hideSpinner());
          dispatch(storeCardInfo('', paymentInstrumentId));
          dispatch(storePageInfo(PageStatus.ERROR));
          logger.log(
            `Unable to get card details::Error Code::${response.status}`
          );
        }
      })
      .catch((err) => {
        dispatch(hideSpinner());
        dispatch(storeCardInfo('', paymentInstrumentId));
        dispatch(storePageInfo(PageStatus.ERROR));
        logger.log(`Unable to get card details::${err}`);
      });
  };
}

export function updateConsentStatus(
  encryptedPaymentInstrumentId: string,
  linkType: LinkType
) {
  return function (dispatch: any) {
    dispatch(showSpinner());
    const url = `${CONFIG.CRS_CONSUMER_CONSENT_SERVICE}/crs/public/api/v1/consumer-consents/${encryptedPaymentInstrumentId}`;
    return httpService
      .put(url, { linkType })
      .then((response) => {
        if (response.status === 200) {
          dispatch(hideSpinner());
          dispatch(storePageInfo(PageStatus.SUCCESS));
        } else {
          dispatch(hideSpinner());
          dispatch(storePageInfo(PageStatus.ERROR));
          logger.log(
            `Unable to get card details::Error Code::${response.status}`
          );
        }
      })
      .catch((err) => {
        dispatch(hideSpinner());
        dispatch(storePageInfo(PageStatus.ERROR));
        console.error(`Error Removing Email: ${err}`);
      });
  };
}
