import { PageStatus } from '../constants/PageStatus';

export default {
  cardInfo: {
    lastFourDigit: '',
    encryptedPID: '',
  },
  pageInfo: {
    pageStatus: PageStatus.INITIAL,
  },
  progressSpinner: {
    show: false,
  },
};
