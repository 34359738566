import React from 'react';

export default function RemoveMyEmailConfirmation() {
  return (
    <div>
      <div className="mds-row">
        <div className="mds-col-12 mds-text-center mds-mt-6 mds-pt-1">
          <h1 className="mds-title-medium-heavier mds-my-3">
            The contact is being removed
          </h1>
        </div>
      </div>
    </div>
  );
}
