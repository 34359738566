import React from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { MdsButton } from '@mds/react';
import { updateConsentStatus } from '../../actions/pageActions';
import { LinkType } from '../../constants/LinkType';

function RemoveMyEmail() {
  const dispatch = useDispatch();

  const lastFourDigit = useSelector(
    (state: RootStateOrAny) => state.cardInfo.lastFourDigit
  ) as string;

  const encryptedPaymentInstrumentId = useSelector(
    (state: RootStateOrAny) => state.cardInfo.encryptedPID
  ) as string;

  const onRemoveMyEmail = () => {
    dispatch(
      updateConsentStatus(encryptedPaymentInstrumentId, LinkType.NOT_MY_RECEIPT)
    );
  };

  return (
    <div>
      <div className="mds-row">
        <div className="mds-col-12 mds-text-center mds-mt-6 mds-pt-1">
          <div className="mds-col-12 mds-mt-0 mds-px-at-576-4">
            <h1 className="mds-title-medium-heavier mds-my-3">
              Not my receipt
            </h1>
          </div>

          <div className="mds-body-large mds-pt-4">
            This contact was used at a Chase-enabled merchant. You can delete it
            now and tell us new contact information during your next
            transaction.
          </div>

          <div className="mds-body-large mds-pt-4">
            Contact information associate with card{' '}
            <span className="mds-accessible-text">ending in</span> (...
            {lastFourDigit}).
          </div>

          <div className="mds-row">
            <div className="mds-col-12 mds-text-center mds-mt-4">
              <MdsButton
                accessibleText=""
                tabFocusable={true}
                id="removeEmail"
                variant="primary"
                widthType="content"
                text="Delete contact information"
                onClick={onRemoveMyEmail}
                data-testid="removeEmailTestId"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RemoveMyEmail;
