import { combineReducers } from 'redux';
import pageInfoReducer from './pageInfoReducer';
import spinnerReducer from './spinnerReducer';
import cardInfoReducer from './cardInfoReducer';

const rootReducer = combineReducers({
  cardInfo: cardInfoReducer,
  pageInfo: pageInfoReducer,
  progressSpinner: spinnerReducer,
});

export default rootReducer;
