/* istanbul ignore file */
function init() {}

function log(error: any) {
  console.error(error);
}

const logService = {
  init,
  log,
};

export default logService;
