import React from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { MdsButton } from '@mds/react';
import { updateConsentStatus } from '../../actions/pageActions';
import { LinkType } from '../../constants/LinkType';

function RemoveProfile() {
  const dispatch = useDispatch();

  const lastFourDigit = useSelector(
    (state: RootStateOrAny) => state.cardInfo.lastFourDigit
  ) as string;

  const encryptedPaymentInstrumentId = useSelector(
    (state: RootStateOrAny) => state.cardInfo.encryptedPID
  ) as string;

  const onRemoveMyProfile = () => {
    dispatch(
      updateConsentStatus(encryptedPaymentInstrumentId, LinkType.REMOVE_PROFILE)
    );
  };

  return (
    <div>
      <div className="mds-row">
        <div className="mds-col-12 mds-text-center mds-mt-6 mds-pt-1">
          <div className="mds-col-12 mds-mt-0 mds-px-at-576-4">
            <h1 className="mds-title-medium-heavier mds-my-3">
              Digital receipt preferences
            </h1>
          </div>
          <div className="mds-body-large mds-pt-4">
            You recently requested a digital receipt from a Chase-enabled
            merchant, and then indicated you prefer to opt out of having us save your contact info to send receipts.
          </div>
          <div className="mds-body-large mds-pt-4">
            If you opt out, we'll delete any email address or phone number we had saved to this card{' '}
            <span className="mds-accessible-text">ending in</span> (...
            {lastFourDigit}) to send receipts. Would you like to continue?
          </div>
          <div className="mds-row">
            <div className="mds-col-12 mds-text-center mds-mt-4">
              <MdsButton
                accessibleText=""
                tabFocusable={true}
                id="removeProfile"
                variant="primary"
                widthType="content"
                text="Delete and don't save"
                onClick={onRemoveMyProfile}
                data-testid="removeProfileTestId"
              />
            </div>
          </div>
          <div className="mds-body-large mds-pt-4">
            Keep in mind, even if you opt out, you can still get digital receipts if you request it. We just won't save your info.
          </div>
        </div>
      </div>
    </div>
  );
}

export default RemoveProfile;
