import React from 'react';
import { MdsLink } from '@mds/react';
import { DISCLAIMER, privacyPolicyUrl, supportPageUrl } from '../constants/footer';

const mdsAt576 = 'mds-col-12';
const mdsAt768 = 'mds-col-at-768-10 mds-offset-at-768-1';
const mdsAt992 = 'mds-col-at-992-6 mds-offset-at-992-3';
const mdsAt1200 = 'mds-col-at-1200-6 mds-offset-at-1200-3';

const handleClick = (url: string) => {
  window.location.assign(url);
};

const Footer = () => {
  return (
    <div className="mds-container-fluid">
      <div className="mds-row">
        <div className="mds-col-12 mds-mb-6 mds-mt-6 mds-body-small footer">
          <div className="mds-row">
            <div
              className={`${mdsAt576} ${mdsAt768} ${mdsAt992} ${mdsAt1200} mds-mt-5 mds-px-5 mds-body-small`}
            >
              <p>
                {DISCLAIMER.headerText}
                <MdsLink
                  data-testid="privacy-policy-link"
                  id="privacy-policy-link"
                  text="Privacy Policy"
                  underline={true}
                  onClick={() => handleClick(privacyPolicyUrl)}
                  accessibleText=""
                />
                {'.'}
              </p>
              <p>
                {DISCLAIMER.subText}
                <MdsLink
                  data-testid="support-link"
                  id="support-link"
                  text="support page"
                  underline={true}
                  onClick={() => handleClick(supportPageUrl)}
                  accessibleText=""
                />
                {'.'}
              </p>

              <p>
                {DISCLAIMER.fdicText} <br />
                {`© ${new Date().getFullYear()} ${DISCLAIMER.copyrightText}`}
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
