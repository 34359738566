import React from 'react';

export default function ErrorPage() {
  return (
    <div>
      <div className="mds-row">
        <div className="mds-col-12 mds-text-center mds-mt-6 mds-pt-1">
          <div className="mds-col-12 mds-mt-0 mds-px-at-576-4">
            <h1 className="mds-title-medium-heavier mds-my-3">
              Contact Information could not be found
            </h1>
          </div>

          <div className="mds-body-large mds-pt-4">
            We couldn't find a profile with that reference.
          </div>
        </div>
      </div>
    </div>
  );
}
