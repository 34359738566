import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers/index';
import thunk from 'redux-thunk';

const composeWithEnhancers = () => {
  return compose(applyMiddleware(thunk));
};

export default function configureStore(initialState: {} | undefined) {
  return createStore(rootReducer, initialState, composeWithEnhancers());
}
