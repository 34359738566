/* istanbul ignore file */
import axios, { AxiosRequestConfig } from 'axios';
import logger from './logService';
import axiosRetry from 'axios-retry';
import { v4 as uuidv4 } from 'uuid';

// Default config options
// @ts-ignore
axios.defaults.headers = {
  'Content-Type': 'application/json',
  'channel-type': 'WEB',
  'channel-identifier': 'PCF'
};

// Set the AUTH token for any request
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

//and a new trace-id header for each request
axios.interceptors.request.use(function (config) {
  const traceId = uuidv4();
  const requestId = uuidv4();
  config.headers['trace-id'] = traceId;
  config.headers['request-id'] = requestId;
  config.headers['X-Content-Type-Options'] = 'no-sniff';
  config.headers['Strict-Transport-Security'] = 'max-age=31536000';
  return config;
});

axios.interceptors.response.use(undefined, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    logger.log(error);
  }

  return Promise.reject(error);
});

axiosRetry(axios, {
  retries: 0,
  retryDelay: (retryCount) => {
    logger.log(`retry attempt: ${retryCount}`);
    return retryCount * 2000; // time interval between retries
  },
  shouldResetTimeout: true,
  retryCondition: (_error) => true, // retry no matter what
});

const httpService = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
  axios: axios,
};

export default httpService;
