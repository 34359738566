import {
  SHOW_PROGRESS_SPINNER,
  HIDE_PROGRESS_SPINNER,
} from '../actions/actionTypes';
import initialState from './initialState';

export default function spinnerReducer(
  state = initialState.progressSpinner,
  action: { type: any }
) {
  switch (action.type) {
    case SHOW_PROGRESS_SPINNER:
      return {
        ...state,
        show: true,
      };
    case HIDE_PROGRESS_SPINNER:
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
}
