import initialState from '../reducers/initialState';
import { STORE_PAGE_INFO } from '../actions/actionTypes';
import { PageStatus } from '../constants/PageStatus';

export default function pageInfoReducer(
  state = initialState.pageInfo,
  action: {
    type: any;
    payload: { pageStatus: PageStatus };
  }
) {
  switch (action.type) {
    case STORE_PAGE_INFO:
      return {
        ...state,
        pageStatus: action.payload.pageStatus,
      };
    default:
      return state;
  }
}
