import React from 'react';
import { updateConsentStatus } from '../../actions/pageActions';
import { MdsButton } from '@mds/react';
import { LinkType } from '../../constants/LinkType';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';

function ReEnableProfile() {
  const dispatch = useDispatch();

  const lastFourDigit = useSelector(
    (state: RootStateOrAny) => state.cardInfo.lastFourDigit
  ) as string;

  const encryptedPaymentInstrumentId = useSelector(
    (state: RootStateOrAny) => state.cardInfo.encryptedPID
  ) as string;

  const onReEnableProfile = () => {
    dispatch(
      updateConsentStatus(
        encryptedPaymentInstrumentId,
        LinkType.RENABLE_PROFILE
      )
    );
  };

  return (
    <div>
      <div className="mds-row">
        <div className="mds-col-12 mds-text-center mds-mt-6 mds-pt-1">
          <div className="mds-col-12 mds-mt-0 mds-px-at-576-4">
            <h1 className="mds-title-medium-heavier mds-my-3">
              Digital receipt preferences
            </h1>
          </div>

          <div className="mds-body-large mds-pt-4">
            Allow your contact to be stored for digital receipts by telling us
            your contact information during the next transaction.
          </div>

          <div className="mds-body-large mds-pt-4">
            Contact information associate with card{' '}
            <span className="mds-accessible-text">ending in</span> (...
            {lastFourDigit}).
          </div>

          <div className="mds-row">
            <div className="mds-col-12 mds-text-center mds-mt-4">
              <MdsButton
                accessibleText=""
                tabFocusable={true}
                id="resetProfile"
                variant="primary"
                widthType="content"
                text="Allow storage"
                onClick={onReEnableProfile}
                data-testid="resetProfileTestId"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReEnableProfile;
