import { REGEX } from '../../constants/regex';
import LOCAL_CONFIG from './local';
import DEV_CONFIG from './dev';
import TEST_CONFIG from './uat';
import PROD_CONFIG from './prod';

let CONFIG;
const results = REGEX.ENVIRONMENTS.exec(window.location.hostname);
const environment = results ? results[0] : 'prod';
switch (environment) {
  case 'local':
    CONFIG = LOCAL_CONFIG;
    break;
  case 'dev':
    CONFIG = DEV_CONFIG;
    break;
  case 'ist':
    CONFIG = DEV_CONFIG;
    break;
  case 'test':
    CONFIG = TEST_CONFIG;
    break;
  case 'qa':
    CONFIG = TEST_CONFIG;
    break;
  case 'uat':
    CONFIG = TEST_CONFIG;
    break;
  case 'prod':
    CONFIG = PROD_CONFIG;
    break;
  default:
    CONFIG = LOCAL_CONFIG;
}

const FINAL_CONFIG = CONFIG;
export default FINAL_CONFIG;
