import React from 'react';
import { MdsProgressSpinner } from '@mds/react';
import { RootStateOrAny, useSelector } from 'react-redux';

function Spinner(): JSX.Element {
  const show = useSelector(
    (state: RootStateOrAny) => state.progressSpinner.show
  );

  return (
    <MdsProgressSpinner
      loading-label="Loading . . ."
      id={'progress-spinner'}
      open={show}
      data-testid={'spinner'}
    />
  );
}

export default Spinner;
